import React from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/graphql-error-list";
import Seo from "../components/seo";
import Layout from "../containers/layout";
import Helmet from "react-helmet";

import Heros from "../components/heros";
import FlexibleContent from "../components/flexible-content";

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query DefaultPageTemplateQuery($id: String!) {
    pageDefault: sanityPageDefault(id: { eq: $id }) {
      id
      title
      slug {
        current
      }
      description
      featuredImage {
        ...SanityImage
        alt
      }
      heros {
        _key
        image {
          ...SanityImage
          alt
        }
        imageMobile {
          ...SanityImage
          alt
        }
        video
        videoUrl
        title
        blurb
        link {
          blank
          text
          url
        }
      }
      noindex
      flexibleContent {
        ... on SanityFcContentBlock {
          _key
          _type
          alignRight
          _rawText
        }
        ... on SanityFcFeaturedProducts {
          _key
          _type
          fillerImage {
            ...SanityImage
            alt
          }
          fillAlignRight
          featuredProducts {
            _key
            product {
              title
              slug {
                current
              }
              description
              featuredImage {
                ...SanityImage
                alt
              }
              secondaryFeaturedImage {
                ...SanityImage
                alt
              }
            }
          }
        }
        ... on SanityFc2ColumnImage {
          _key
          _type
          paddedLeft
          paddedRight
          imageLeft {
            ...SanityImage
            alt
            caption
          }
          imageRight {
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcSlider {
          _key
          _type
          slides {
            _key
            ...SanityImage
            alt
            caption
          }
        }
        ... on SanityFcVideo {
          _key
          _type
          url
          alignment
          thumbnail {
            ...SanityImage
            alt
          }
        }
        ... on SanityFcBensenEveryday {
          _key
          _type
          intro
          overrides {
            _key
            bensenEveryday {
              title
              slug {
                current
              }
              description
              featuredImage {
                ...SanityImage
                alt
              }
            }
          }
        }
      }
    }
  }
`;

const DefaultPageTemplate = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }
  
  const pageDefault = (data || {}).pageDefault;

  return (
    <Layout>
      {pageDefault.noindex ? (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      ) : (
        <Seo
          title={pageDefault.title}
          description={pageDefault.description}
          image={pageDefault.featuredImage}
        />
      )}

      {pageDefault.heros && (<Heros items={pageDefault.heros} />)}
      {pageDefault.flexibleContent && (<FlexibleContent items={pageDefault.flexibleContent} />)}
    </Layout>
  );
};

export default DefaultPageTemplate;
